import { Controller } from '@hotwired/stimulus'
import tippy from 'tippy.js';

export default class extends Controller {
  static targets = ['actions', 'text', 'input', 'field']

  disconnect() {
    this.destroy()
  }

  inputTargetConnected(element) {
    this._higlightFragment(element.value, element.dataset.tag)

    if (element.value) {
      element.readOnly = false
      element.dataset.previousValue = element.value
    }
  }

  tooltip(event) {
    if (window.getSelection().isCollapsed) {
      return this.destroy()
    }

    if (!this.textTarget.contains(event.target)) { return }

    this.destroy()
    this.tooltipInstance = this._initializeTippy(event.target)
  }

  destroy() {
    if (!this.tooltipInstance) { return }

    this.tooltipInstance.destroy()
    this.tooltipInstance = null
  }

  update({ params }) {
    if (!params.tag) { return }

    const selection = window.getSelection().toString()
    if (selection.trim() === '') { return }

    this.inputTargets.forEach((element) => {
      if (element.dataset.tag == params.tag) {
        element.value = selection
        element.readOnly = false
        element.dataset.previousValue = selection
      }
    })

    this._removeHighlight(params.tag)
    this._higlightFragment(selection, params.tag)
  }

  updateText({ target }) {
    if (!target.dataset.previousValue) { return }

    this._removeHighlight(target.dataset.tag)

    if (target.value.trim()) {
      this.textTarget.innerHTML = this.textTarget.innerHTML.replace(
        target.dataset.previousValue,
        target.value
      )
      target.dataset.previousValue = target.value

      this._higlightFragment(target.value, target.dataset.tag)
      this.fieldTarget.value = this.textTarget.innerText
    } else {
      target.readOnly = true
    }
  }

  _initializeTippy(element) {
    return tippy(element, {
      content: this.actionsTarget.innerHTML,
      theme: 'light',
      allowHTML: true,
      interactive: true,
      zIndex: 999999,
      showOnCreate: true,
      placement: 'top',
      trigger: 'manual',
      onClickOutside(instance,) {
        if (instance.state.isVisible) {
          instance.hide()
        }
        if (!instance.state.isDestroyed) {
          instance.destroy()
        }
      }
    })
  }

  _higlightFragment(phrase, tag) {
    if (phrase.trim() === '') { return }

    this.textTarget.innerHTML = this.textTarget.innerHTML.replace(
      new RegExp(phrase, 'i'),
      `<mark class="tag-${tag}">${phrase}</mark>`
    )
  }

  _removeHighlight(tag) {
    const mark = this._findTag(tag)
    if (mark) {
      this.textTarget.innerHTML = this.textTarget.innerHTML.replaceAll(
        mark.outerHTML, mark.textContent
      )
    }
  }

  _findTag(tag) {
    return this.textTarget.querySelector(`.tag-${tag}`)
  }
}
