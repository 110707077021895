import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['output', 'template', 'element']
  newValue;

  push(event) {
    if (!event.params.attrName) { return }
    const attrValue = event.currentTarget.dataset[event.params.attrName]
    if (!attrValue) { return }

    this.newValue = event.currentTarget.value

    if (!this.newValue) { return }

    const selector = `[data-${event.params.attrName}="${attrValue}"]`
    const matchingElements = this.element.querySelectorAll(selector)

    // clear out the outputTarget
    this.outputTarget.replaceChildren()
    matchingElements.forEach((el) => {
      if (el === event.currentTarget) { return }
      this._renderInput(el)
    })

    // matchingElements will always include event.currentTarget which we can ignore
    if (matchingElements.length > 1) {
      this.dispatch('push', { detail: { boxId: 'propagate_changes' } })
    }
  }

  apply(event) {
    this.elementTargets.forEach((target) => {
      if (target.checked) {
        const element = document.getElementById(target.value)
        console.log(`updating "${element.value}" to "${this.newValue}"`)
        element.value = this.newValue
      }
    })
    this.dispatch('apply', { target: event.currentTarget })
  }

  _renderInput(element) {
    const elementLocation = this._findTabName(element)

    let content = this.templateTarget.innerHTML.replace(/TMPLID/g, element.id)
    content = content.replace(/TMPLLABEL/g,
                              `Update "${element.value}" to "${this.newValue}" on "${elementLocation}"`)

    this.outputTarget.insertAdjacentHTML('beforeend', content)
  }

  _findTabName(element) {
    const tabContent = element.closest('.tab-content')
    const tabLink = document.querySelector(`[data-tab="${tabContent.id}"]`)

    return tabLink.innerText
  }
}
