import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  connect() {
    // preselect first tab as active
    const tabId = this.allTabsLink[0].dataset.tab;
    this._setActiveTab(tabId);
  }

  change(event) {
    let tabId = event.currentTarget.dataset.tab
    this._setActiveTab(tabId)
  }

  switchTo({ params }) {
    this._setActiveTab(params.tabId);
  }

  _setActiveTab(tabId) {
    this.allTabsLink.forEach(link => {
      link.classList.remove('current');
    })
    this.allTabsContent.forEach(tab => {
      tab.classList.remove('current');
    })
    this.element.querySelector(`.tab-link[data-tab="${tabId}"]`).classList.add('current');
    this.element.querySelector(`#${tabId}`).classList.add('current')
  }

  get allTabsLink() {
    return this.element.querySelectorAll('ul.tabs li');
  }

  get allTabsContent() {
    return this.element.querySelectorAll('.tab-content');
  }
}
