import { Controller } from '@hotwired/stimulus'

const upKey = 38
const downKey = 40
const enterKey = 13
const escKey = 27
const navigationKeys = [upKey, downKey, enterKey, escKey]

export default class extends Controller {
  static targets = [ 'query', 'results', 'value' ]
  static values = { url: String }
  currentResultIndex;
  previousQuery;
  timer;
  results;

  disconnect() {
    this.reset()
  }

  connect() {
    const controller = this;

    controller.currentResultIndex = -1
    controller.previousQuery = '';
    controller.timer = null;
    controller.autocomplete()
    controller.results = {}
  }

  autocomplete() {
    const controller = this

    if (controller.hasUrlValue) {
      controller.queryTarget.addEventListener('input', (event) => {
        controller.fetchResults(event)
      });
    } else if (controller.hasResultsTarget) {
      controller.queryTarget.addEventListener('focus', (_event) => {
        controller.resultsTarget.classList.add('open');
      });
    }

    document.addEventListener('click', (event) => {
      // ignore clicks on the input field
      if (event.target === controller.queryTarget) { return; }
      controller.closeAllLists();
    });
  }

  setValue(event) {
    const controller = this

    if (controller.hasValueTarget) {
      controller.valueTarget.value = event.currentTarget.dataset.value;
    }
    controller.queryTarget.value = event.currentTarget.dataset.result;
    controller.queryTarget.dispatchEvent(new Event('change'))
    controller.closeAllLists();
  }

  selectNextResult() {
    const controller = this
    controller.results = controller.resultsTarget.getElementsByClassName('result');
    if (controller.results.length == 0) return

    controller.currentResultIndex++;
    controller.selectCurrentResult(controller.results);
  }

  selectPreviousResult() {
    const controller = this
    controller.results = controller.resultsTarget.getElementsByClassName('result');
    if (controller.results.length == 0) return

    controller.currentResultIndex--;
    controller.selectCurrentResult(controller.results);
  }

  selectCurrentResult(results) {
    const controller = this
    controller.results = controller.resultsTarget.getElementsByClassName('result');

    if (!controller.results) return false;

    controller.removeActive();

    if (controller.currentResultIndex >= results.length) {
      controller.currentResultIndex = 0;
    }
    if (controller.currentResultIndex < 0) {
      controller.currentResultIndex = (results.length - 1);
    }

    results[controller.currentResultIndex].classList.add('autocomplete-active');
  }

  navigateResults(event) {
    const controller = this

    if (!navigationKeys.includes(event.keyCode)) { return }
    event.preventDefault()

    if (controller.hasResultsTarget) { 
      controller.results = controller.resultsTarget.getElementsByClassName('result'); 
    }
    controller.resultsTarget.classList.add('open');
    switch(event.keyCode) {
      case downKey:
        controller.selectNextResult();
        break;
      case upKey:
        controller.selectPreviousResult();
        break;
      case enterKey:
        if (controller.currentResultIndex > -1) {
          if (controller.results.length > 0) {
            controller.results[controller.currentResultIndex].click();
          }
        } else {
          if (controller.results.length == 0) {
            controller.resultsTarget.innerHTML = ''
            controller.previousQuery = -1
          }
        }
        break;
      case escKey:
        if (controller.results.length > 0) { event.stopPropagation(); }
        controller.closeAllLists();
        break;
    }
  }

  removeActive() {
    const controller = this
    controller.results = controller.resultsTarget.getElementsByClassName('result');
    Array.from(controller.results).forEach((result, _idx) => {
      result.classList.remove('autocomplete-active')
    });
  }

  closeAllLists() {
    if (!this.hasResultsTarget) { return; }

    this.resultsTarget.classList.remove('open');
  }

  fetchResults(_event) {
    const controller = this

    if (!controller.hasUrlValue) { return; }

    if (controller.queryTarget.value == "") {
      controller.reset()
      return
    }
    if (controller.queryTarget.value == controller.previousQuery) { return }

    controller.previousQuery = controller.queryTarget.value

    this._fetchRemoteResults(controller)
  }

  reset() {
    this.resultsTarget.innerHTML = ""
    this.queryTarget.value = ""
    this.previousQuery = null
  }

  abortPreviousFetchRequest() {
    if(this.abortController) {
      this.abortController.abort()
    }
  }

  _fetchRemoteResults(controller) {
    const url = new URL(controller.urlValue)
    url.searchParams.append('query', controller.queryTarget.value)
    controller.abortPreviousFetchRequest()
    if (controller.timer != null) {
      clearTimeout(controller.timer);
    }
    controller.abortController = new AbortController();
    controller.timer = setTimeout(()=> {
      fetch(url, { signal: controller.abortController.signal })
        .then(response => response.text())
        .then(html => {
          controller.resultsTarget.classList.add('open');
          controller.resultsTarget.innerHTML = html;
          let suggestionsBox = controller.resultsTarget.closest('.suggestions-box')
          if (suggestionsBox.offsetHeight <= 240) {
            controller.resultsTarget.classList.remove('bottom');
          } else {
            controller.resultsTarget.classList.add('bottom');
          }
        })
        .catch(() => {})
        .finally(() => {
          controller.timer = null;
        })
    }, 500)
  }
}
