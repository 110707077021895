import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {}
  toggleOverlay(_event) {
    this.documentsColumn.classList.toggle('visible');
  }

  showPanel(boxId) {
    const box = document.getElementById(`overlay_${boxId}`);
    const pageWrapper = box.closest('.content-wrapper')
    box.classList.add('visible');
    pageWrapper.classList.add('overlay-open')
    let baseColumn = pageWrapper.querySelector('.column.studies')
    if (localStorage.getItem('studyPanelColumnWidth')) {
      let columnWidth = localStorage.getItem('studyPanelColumnWidth')
      baseColumn.width = `${columnWidth}px`;
      box.style.width = `calc(100% - ${columnWidth}px)`;
    } else {
      if (baseColumn) { baseColumn.style.width = '25%' }
      box.style.width = '75%'
    }
  }

  show(event) {
    if (event.ctrlKey) {
      return false;
    }
    const boxId = event.currentTarget.dataset.boxId
    // const box = document.getElementById(`overlay_${boxId}`);
    this.showPanel(boxId)
    let table = event.currentTarget.closest('table')
    if (table) {
      let rows = table.querySelectorAll('tr.current')
      rows.forEach(row => {
        row.classList.remove('current')
      });
      let currentTr = event.currentTarget.closest('tr')
      currentTr.classList.add('current')
    }
  }

  close(event) {
    let overlay = event.currentTarget.closest('.overlay')
    overlay.classList.remove('visible')
    const pageWrapper = overlay.closest('.content-wrapper')
    pageWrapper.classList.remove('overlay-open')
    let domId = event.currentTarget.dataset.domId
    document.getElementById(domId).classList.remove('current')
    if (localStorage.getItem('studyPanelColumnWidth')) {
      localStorage.removeItem('studyPanelColumnWidth');
    }
    if (localStorage.getItem('studyFormColumnWidth')) {
      localStorage.removeItem('studyFormColumnWidth');
    }
    let baseColumn = pageWrapper.querySelector('.column.studies')
    let overlayColumn = pageWrapper.querySelector('.column.overlay')
    if (baseColumn) { baseColumn.style.width = '100%' }
    if (overlayColumn) { overlayColumn.style.width = '0' }
  }
}