import { Controller } from '@hotwired/stimulus'
import { post } from '@rails/request.js'

export default class extends Controller {
  static values = {
    clientSource: { type: String, default: 'https://accounts.google.com/gsi/client' },
    clientId: String,
    authCallbackUrl: String,
    afterAuthCallbackUrl: String,
    afterAuthCallbackFormat: { type: String, default: 'html' },
    loginHint: String,
    authScopes: Array,
  }

  connect() {
    this.initializeClientLibrary()
  }

  auth() {
    this.client.requestAccessToken()
  }

  signInCallback(result) {
    const controller = this
    post(this.authCallbackUrlValue, { body: result })
      .then((res) => {
        if (res.ok) { controller._afterAuth() }
      })
      .catch((err) => console.log(err))
  }

  _afterAuth() {
    if (this.hasAfterAuthCallbackUrlValue) {
      post(this.afterAuthCallbackUrlValue,
           { responseKind: this.afterAuthCallbackFormatValue })
    }
  }

  get client() {
    return google.accounts.oauth2.initTokenClient({
      client_id: this.clientIdValue,
      callback: this.signInCallback.bind(this),
      scope: this.authScopesValue.join(' '),
      login_hint: this.loginHintValue
    })
  }

  initializeClientLibrary() {
    if (typeof(google) !== 'undefined') { return }

    const script = document.createElement('script')

    script.src = this.clientSourceValue;
    script.async = true
    script.defer = true

    document.head.appendChild(script)
  }
}
