import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'form', 'field' ]

  connect() {}

  fieldTargetConnected(element) {
    const controller = this;
    element.addEventListener('change', (_event) => {
      controller.formTarget.requestSubmit()
    });
  }
}
