import { Controller } from '@hotwired/stimulus'
export default class extends Controller {
  currentFrameActive(event) {
    let currentButton = event.currentTarget
    let activeFrame = currentButton.dataset.entity
    let isActive = currentButton.classList.contains('active')

    let filtersContainers = this.element.querySelectorAll('.entity-filter-bar')
    let buttons = this.element.querySelectorAll('.model-filter-bar .button')

    filtersContainers.forEach(element => {
      element.classList.remove('active')
      if (!isActive && (element.classList.contains(activeFrame)) ) {
        element.classList.add('active')
      }
    })

    buttons.forEach(element =>{
      element.classList.remove('active')
    })

    if (isActive) { return }

    event.currentTarget.classList.add('active')
  }

  toggleSidebar(event) {
    const frame = document.getElementById(event.currentTarget.dataset.turboFrame)

    if (frame.src == event.currentTarget.href) {
      event.stopImmediatePropagation()
      event.preventDefault()

      frame.replaceChildren()
      frame.src = null
    }
  }

  hideFrame(event) {
    let frameBox = event.currentTarget.closest('.single-box')
    frameBox.classList.add('hidden')
  }

  entitySubmit(event) {
    if (event.detail.success) {
      let frameBox = event.detail.formSubmission.formElement.closest('.entity-form-container')
      frameBox.classList.add('hidden')
      console.log('Form successfully submitted')
    } else {
      console.log('Error!')
    }
  }
}
