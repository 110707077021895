import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['trigger']
  static outlets = ['autocomplete']
  static classes = ['active']

  connect() {
    this.triggerTargets.forEach(el => this._toggleActiveClass(el))
  }

  autocompleteOutletConnected(outlet, _element) {
    this.triggerTargets.forEach((el) => {
      outlet.setFilterValue(el.dataset.filterName, el.dataset.value)
    })
  }

  toggle({ target }) {
    target.dataset.value = !this.mapping[target.dataset.value]
    this._toggleActiveClass(target)

    if (this.hasAutocompleteOutlet) {
      this.autocompleteOutlet.applyFilter(target.dataset.filterName, target.dataset.value)
    }
  }

  _toggleActiveClass(element) {
    if (this.mapping[element.dataset.value]) {
      element.classList.add(this.activeClass)
    } else {
      element.classList.remove(this.activeClass)
    }
  }

  get mapping() {
    return {
      true: true,
      false: false
    }
  }
}
